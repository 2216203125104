import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import GraphQLExample from '../../../components/GraphQLExample';
import createExample from '../../../examples/createSignatureOrder/pdfFormFilling';
import PdfFormFillingTour from './PdfFormFillingTour';
export const _frontmatter = {
  "product": "signatures",
  "category": "Guides",
  "sort": 10,
  "title": "PDF form filling"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`PDF documents can contain form and input elements enabling users to add additional information to documents or make selections.`}</p>
    <p>{`When form filling is enabled a overlay will be rendered in the browser so that users input information,
the input will then be flattened into the PDF upon the first signature.`}</p>
    <p><strong parentName="p">{`Caveats`}</strong></p>
    <ul>
      <li parentName="ul">{`PDF form filling is only supported for the first signatory`}</li>
      <li parentName="ul">{`Dropdown/Combo boxes currently have some rendering issues when flattened`}</li>
    </ul>
    <h2>{`Creating a signature`}</h2>

    <p>{`To enable form filling you must explicitely enable the feature for each PDF document that you add,
the example blow shows how to creates a signature order with form filling enabled.`}</p>
    <GraphQLExample example={createExample} mdxType="GraphQLExample" />
    <h2>{`Interactive tour`}</h2>

    <PdfFormFillingTour mdxType="PdfFormFillingTour" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      